#kt_header, #kt_subheader {
  z-index: 100;
}
.content {
  margin: 0 !important;
  padding: 0 !important;
}
.login {
  .aside-overlay {
    background-color: #00000033;

    h1 {
      font-size: 34px;
    }
    img {
      width: 400px;
      margin: 0 auto;
    }
  }
}
.login-form {
  input {
    color: #000;
    background-color: #e8f0fe !important;
    border-color: #e8f0fe !important;
  } 
  .input-group-append {
    padding: 2px !important;
    background-color: #e8f0fe !important;
    border-color: #e8f0fe !important;
    border-top-right-radius: 6px !important;;
    border-bottom-right-radius: 6px !important;;

    span {
      background-color: #e8f0fe !important;
      border-color: #e8f0fe !important;
      border-radius: unset !important;
      border-left: 1px solid #fff !important;
    }
  }
  .svg-icon.svg-icon-primary svg g [fill] {
    fill: #ff6757!important;
  }
}
.modal-close-btn {
  right: 14px !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.20) !important;
}
form label {
  margin-bottom: 0 !important;
}

input:focus, button:focus, text-area:focus {
  outline: none !important;
}
.loading-overlay {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.20);
  border-radius: 5px;
}
.align-items-unset {
  align-items: unset !important;
}
.apexcharts-legend {
  display: unset !important;
}
.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: block !important;
}
.onboard-tbl th:nth-child(4),
.onboard-tbl td:nth-child(4) {
  border-right: 1px solid #EEF0F8;
}

.custom-form-input {
  fieldset {
    border: 0 !important;
  }
  .MuiInputBase-formControl {
    background-color: #F3F6F9 !important;
  }
}

.MuiPopover-root {
  z-index: 99999 !important;
}

.font-poppins {
  font-family: Poppins, Helvetica, "sans-serif" !important;
}

.bg-rh-orange {
  background-color: #ff6e5f !important;
}

.w-fit-content {
  width: fit-content !important;
}

.application-form {

  .form-group label,
  .form-group input,
  .form-group div,
  .form-group span {
    font-size: 13px !important;
  }

  .form-group input {
    background-color: #f1f0f0 !important;
  }

  input {
    background-color: #f1f0f0 !important;
  }

}
.custom-form {
  .form-group input {
    //background-color: #f1f0f0 !important;
    background-color: #f3f6f9 !important;
  }
  .MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
    content: none !important;
  }
  .MuiFilledInput-input {
    padding: 15px 12px !important;
  }
  .MuiFilledInput-root, .MuiFilledInput-input {
    border-radius: 0.42rem !important;
  }
}
.custom-select-field {
  .MuiSelect-select {
    text-transform: capitalize;
  }
}
.review-modal {
  .MuiDialog-paper {
    width: 100% !important;
    max-width: unset !important;
    margin: 0;
    height: 100vh !important;
    max-height: unset !important;
    background-color: #7a7c7d !important;
  }
  .react-pdf__Document {
    width: fit-content !important;
  }
}

.table-card {
  .card-header {
    border: 0 !important;
    padding-top: 15px!important;
  }
  th {
    border-bottom: 1px solid #8b909b !important;
  }
}

.brand {
  background-color: #ff6757 !important;
}

.aside, .aside-menu {
  background-color: #fff !important;
}

.menu-link {
  .menu-text {
    color: #3F4254 !important;
  }

  .menu-icon.svg-icon svg g [fill] {
    fill: #3F4254 !important;    
  }
}

.menu-item.menu-item-active > .menu-link {
  background-color: #fff !important;  

  .menu-text {
    color:#ff6757 !important;
    font-weight: bold !important;
  }

  .menu-icon.svg-icon svg g [fill] {
    fill: #ff6757 !important;    
  }
}

.menu-link:hover {
  background-color: #fff !important;  

  .menu-text {
    color:#ff6757 !important;
  }

  .menu-icon.svg-icon svg g [fill] {
    fill: #ff6757 !important;    
  }
}

.brand-toggle .svg-icon svg g [fill] {
  fill: #fff !important;    
}

.brand-toggle:hover {
  .svg-icon svg g [fill] {
    fill: #61b390 !important;    
  }
}

.text-green {
  color: #61b390 !important;
}

.btn-rh-green {
  background-color: #61b390 !important;
}

.bg-green {
  background-color: #61b390 !important;
}

.bg-gray-preview {
  background-color: #7a8599 !important;
}

.w-45 {
  width: 45% !important;
}
.min-w-45 {
  min-width: 45% !important;
}
.max-w-45 {
  max-width: 45% !important;
}
